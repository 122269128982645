/* App-wide layout */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the app spans the entire viewport */
}

/* Navbar styling */
header {
  background-color: #2C2C54; /* Blue background for navbar */
  color: white;
  padding: 10px 0;
  text-align: center;
}

/* Content Layout */
.content-layout {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr; /* Left ad, main content, right ad */
  gap: 20px; /* Spacing between sections */
  padding: 20px;
  flex: 1; /* Ensure it takes up remaining height */
}

/* Ad Space Styling */
.ad-space {
  background-color: #f0f0f0; /* Light gray background for ad spaces */
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd; /* Add a light border */
}

/* Main Content Styling */
.main-content {
  background-color: #fff; /* White background for main content */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border: 1px solid #ddd; /* Add a light border */
}

/* Footer styling */
.footer {
  background-color: #2C2C54; /* Dark footer background */
  color: white;
  text-align: center;
  padding: 10px 0;
}
