.image-to-text-container {
  text-align: left;
}

.image-preview img {
  margin-top: 20px;
  border: 1px solid #ccc;
  padding: 5px;
}

.ocr-result {
  margin-top: 20px;
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
}


/* Styles for each button */
.ocr-button {
  background-color: #048f22; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 5px 10px; /* Padding for spacing */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  font-size: 16px; /* Set font size */
  margin: 0 10px; /* Spacing between buttons */
  cursor: pointer; /* Pointer cursor on hover */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}

/* Hover effect for the buttons */
.ocr-button button:hover {
  background-color: #45a049; /* Darker green on hover */
}

/* Active button style (if you want to highlight the active one) */
.ocr-button button.active {
  background-color: #2e7031; /* Even darker green for active state */
}
