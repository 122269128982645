/* custom-navbar.css */

/* Set blue background for the entire navbar */
.main-nav {
  background-color: #2C2C54; /* Bootstrap primary blue */
  color: #ffffff; /* White text for better contrast */
}

/* Optional: Ensure all nav links inherit the color */
.navbar .nav-link {
  color: #ffffff !important; /* White text for links */
}

.navbar .nav-link:hover {
  color: #cce7ff !important; /* Lighter blue on hover */
}

/* Style the brand */
.navbar-brand {
  color: #ffffff !important; /* White text for brand */
  font-weight: bold;
}
