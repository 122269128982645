.line-break-remover {
    padding: 20px;
    padding-top: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .line-break-remover h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .line-break-remover textarea {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .line-break-remover .buttons {
    margin-top: 20px;
  }
  
  .line-break-remover Button {
    padding: 10px 20px;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  .copy-button {
    background-color: #1976d2;
    color: white;
  }
  
  .copy-button:hover {
    background-color: #1565c0;
  }
  