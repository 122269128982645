body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  font-family: Arial, sans-serif;
  height: 100%;
}

/* App container to cover the whole page */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Navbar styling */
.navbar {
  background-color: #2c2c54;
  padding: 15px;
}

.navbar ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.navbar ul li a {
  color: #fff;
  text-decoration: none;
}

/* Layout container for ads and content */
.layout {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  padding: 20px;
}

/* Left and Right Ads */
.left-ad, .right-ad {
  width: 200px;
  background-color: #f7f1e3;
  text-align: center;
  padding: 20px;
  margin: 10px;
}

/* Main content area */
.content {
  flex-grow: 1;
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  /*text-align: center;*/
}

/* Footer */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  margin-top: auto;
  font-size: 0.9em;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .layout {
    flex-direction: column;
  }
  
  .left-ad, .right-ad {
    display: none;
  }

  .content {
    margin: 0;
    padding: 10px;
  }
}

mb-5{
  margin-bottom: 5px;
}

ml-5{
  margin-bottom: 5px;
}

mr-5{
  margin-bottom: 5px;
}

mt-5{
  margin-bottom: 5px;
}